import React, { useState, useEffect } from 'react'
import { Line, Circle } from 'rc-progress';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import nameLogo from "../resources/nameLogo.svg"
import taskCoin from "../resources/taskCoin.svg"
import invite_Icon from "../resources/inviteIcon.svg"
import shild from "../resources/shild.svg"
import potli from "../resources/potli.svg"
import clock from "../resources/clock.svg"
import coinpic from "../resources/coin.png"
import bijli from "../resources/bijli.svg"
import boostRocket from "../resources/boostRocket.svg"
import auzaar from "../resources/auzar.svg"
import haraRocket from "../resources/hara_rocket.svg"
import active36 from "../resources/activeEvent.svg"
import redShild from "../resources/redShild.svg"
import yellowShild from "../resources/yellowShild.svg"
import Rodemap from './Rodemap';
import Website from './website/Website';
import dtCoin from "../resources/dTcoin.svg"
import Footer from './Footer';


function Allthings() {
    const [bandas, setBandas] = useState([])
    const [userRawData, setUserRawData] = useState({})
    const [universalData, setUniversalData] = useState({})
    const [coin, setCoins] = useState()
    const [min, setmin] = useState()
    const [hr, sethr] = useState()
    const [sec, setsec] = useState()
    const [supmin, setSupmin] = useState()
    const [suphr, setSuphr] = useState()
    const [supsec, setSupsec] = useState()
    const [supdays, setSupdays] = useState()
    const [progress, setProgress] = useState()
    const [tpph, settpph] = useState()
    const [bandaInvited, setBandaInvited] = useState()
    const [boostStreek, setBoostStreek] = useState(16)
    const [boostStreekDays, setBoostStreekDays] = useState(7)
    const [mineinactiveTime, setMineinactiveTime] = useState()
    const [boosthr, setBoosthr] = useState()
    const [boostmin, setBoostmin] = useState()
    const [boostsec, setBoostsec] = useState()
    const [copyed, setCopyed] = useState(false)
    const [invitelink, setInvitelink] = useState();
    const [displaypopup, setDisplaypop] = useState(0);
    const [activeClaim, setactiveClaim] = useState(1)
    const [showClaim, setShowClaim] = useState(false)



    let baseMiningSpeed = 0;
    // let inviteby = 0;
    // let teleid = 52352;
    // let newUsername = "bada sa nam lelo bhoi ab";
    // const yoyoyo = (1 ===2 ? "hn sahi hai" : "bhoi Galat hai");
    const alldata = window.Telegram.WebApp.initDataUnsafe;
    // const inviteby = window.Telegram.WebApp.initDataUnsafe.start_param;
    // const teleid = window.Telegram.WebApp.initDataUnsafe.user.id;
    // const newUsername = window.Telegram.WebApp.initDataUnsafe.user.first_name;

    const boostTimer = (thirdboosttime) => {
        const rtimer = setInterval(() => {
            const activetime = new Date(thirdboosttime);
            const remainTime = activetime.getTime() + 24 * 1000 * 60 * 60 - new Date()
            const inactivetime = activetime.getTime() + 24 * 1000 * 60 * 60;
            const hrr = Math.floor(remainTime / (1000 * 60 * 60) % 24)
            const minn = Math.floor((remainTime / 1000 / 60) % 60)
            const secc = Math.floor((remainTime / 1000) % 60)
            if (inactivetime < new Date().getTime()) {
                setBoosthr("00");
                setBoostmin("00");
                setBoostsec("00");
                clearInterval(rtimer);
            } else {
                setBoosthr(hrr);
                setBoostmin(minn);
                setBoostsec(secc);
            }
        }, 1000);
    }

    const supeer36Timer = (eventDate) => {
        const rtimer = setInterval(() => {
            const activetime = new Date(eventDate).getTime();
            const remainTime = activetime - new Date().getTime();
            const suphrr = Math.floor(remainTime / (1000 * 60 * 60) % 24);
            const supminn = Math.floor((remainTime / 1000 / 60) % 60);
            const supsecc = Math.floor((remainTime / 1000) % 60)
            const supdayss = Math.floor(remainTime / (1000 * 60 * 60 * 24));
            if (activetime < new Date().getTime()) {
                setSuphr("00");
                setSupmin("00");
                setSupdays("00");
                setSupsec("00")
                clearInterval(rtimer);
            } else {
                setSuphr(suphrr);
                setSupmin(supminn);
                setSupdays(supdayss);
                setSupsec(supsecc)
            }
        }, 1000);
    }

    const timeRemainFun = (mineactivetime) => {
        const rtimer = setInterval(() => {
            const activetime = new Date(mineactivetime);
            const remainTime = activetime.getTime() + 24 * 1000 * 60 * 60 - new Date()
            const inactivetime = activetime.getTime() + 24 * 1000 * 60 * 60;
            const hrr = Math.floor(remainTime / (1000 * 60 * 60) % 24)
            const minn = Math.floor((remainTime / 1000 / 60) % 60)
            const secc = Math.floor((remainTime / 1000) % 60)
            if (inactivetime < new Date().getTime()) {
                sethr("00");
                setmin("00");
                setsec("00");
                clearInterval(rtimer);
            } else {
                sethr(hrr);
                setmin(minn);
                setsec(secc);
            }
        }, 1000);
    }

    const progressbarFun = (mineactivetime) => {
        const activetime = new Date(mineactivetime);
        const inactivetime = activetime.getTime() + 24 * 1000 * 60 * 60;
        const remainTime = new Date() - activetime.getTime();
        let remainHr = Math.floor(remainTime / (1000 * 60));

        const progress = (100 / 24 / 60) * remainHr;

        if (inactivetime < new Date().getTime()) {
            setProgress(0)
        } else {
            setProgress(100 - progress)
        }
    }

    const getUserData = async () => {
        try {
            let teleid = alldata.user.id;
            let newUsername = alldata.user.first_name;
            let inviteby = alldata.start_param;
            const res = await fetch("/ye-data-do", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teleid, inviteby, newUsername
                })
            });
            const data = await res.json();
            setUserRawData(data);
            setactiveClaim(data.dailyClaim)
            dailyTasks(data.claimdate);

            if (data.invites) {
                setBandaInvited((data.invites).length)
            } else {
                setBandaInvited(0)
            }
            setBandas(data.invites);
            if (res.status == 422) {
                window.alert("Can't Get User Id Please Restart App")
            } else if (res.status == 201) {
                getUserData();
            } else {

                const getUniversalData = async () => {
                    try {
                        const res2 = await fetch("/universal-data", {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json"
                            }
                        });

                        const data2 = await res2.json();
                        setUniversalData(data2);
                        // setInvitelink(data2.inviteLink + teleid);
                        setInvitelink(data2.inviteLink + alldata.user.id);
                        supeer36Timer(data2.super36date);
                        if (res2.status === 422) {
                            window.alert("Can't Get User Id Please Restart App")

                        } else {

                            baseMiningSpeed = (data2.baseMspeed + baseMiningSpeed);
                            let boostpercent = ((100 / 100) * baseMiningSpeed)
                            let incInvitePercent = (((data2.inviteIncreadePerc * (data.invites).length)) / 100) * data2.baseMspeed;
                            // let incInvitePercent = 0;
                            // let super36boost = 1;
                            // if(data2.super36){
                            //     super36boost = 36
                            // }
                            let totalPPH = 0;
                            let coins = data.realcoin;
                            let timesrun = 0;
                            let baseminedd = 0;


                            const coinsFun = (mineactivetime, boostactivetime) => {
                                const activetime = new Date(mineactivetime);
                                const inactivetime = activetime.getTime() + 24 * 1000 * 60 * 60;
                                setMineinactiveTime(inactivetime)
                                const firstBoostTime = new Date(boostactivetime);
                                const firstBostReamTime = new Date().getTime() - firstBoostTime.getTime();
                                const boostInactivTime = firstBoostTime.getTime() + 24 * 1000 * 60 * 60;
                                if (inactivetime > new Date().getTime()) {
                                    // bina boost wala ye hai

                                    const hrRemain = new Date() - activetime.getTime();
                                    let pph1 = ((baseMiningSpeed + incInvitePercent) / 60 / 60);
                                    totalPPH = pph1 + totalPPH;
                                    timesrun = timesrun + 1;
                                    baseminedd = hrRemain / 1000 * pph1;
                                    coins = hrRemain / 1000 * pph1 + coins;
                                    // window.alert(hrRemain / 1000 * pph1 + " ye normail mine ho raha hai abhi 001")

                                    if (inactivetime > new Date().getTime()) {
                                        // iske ander ane ka matlab hai ki ye abhi mine active hai
                                        // first boost

                                        if (mineactivetime <= boostactivetime) {
                                            let pph2 = (boostpercent / 60 / 60);
                                            totalPPH = totalPPH + pph2;
                                            coins = firstBostReamTime / 1000 * pph2 + coins;
                                            // window.alert(firstBostReamTime / 1000 * pph2 + " boost bad me active huwa hai 002")

                                        } else if (mineactivetime >= boostactivetime) {

                                            // boost active huwa hai
                                            // is line ka matlab hai ki boost 24hr bad inactive ho jayega
                                            const mineActiveTime = new Date(mineactivetime).getTime();
                                            if (boostInactivTime > new Date().getTime()) {
                                                // iske ander ane ka matlab hai ki boost phele active ho gaya tha toh jitna time bacha hai utha coin milega


                                                if (boostInactivTime < mineActiveTime) {
                                                    // window.alert("mine se phele boost active huwa tha aur aur boost khatam ho chukah hai 003")
                                                } else {
                                                    const mineToNowTime = new Date().getTime() - new Date(mineactivetime).getTime();
                                                    let pph2 = (boostpercent / 60 / 60);
                                                    totalPPH = totalPPH + pph2;
                                                    coins = mineToNowTime / 1000 * pph2 + coins;
                                                    // window.alert(mineToNowTime / 1000 * pph2 + " mine se phele boost active huwa tha aur boost abhi chal raha hai aur itna coin mil raha hai 004")
                                                }
                                            } else if (boostInactivTime < new Date().getTime()) {
                                                // iske ander ane ka matlab hai ki boost time khatam ho chuka hai toh jitne time tack boos active tha utna coin dedo

                                                const boostkitnaActivetha = boostInactivTime - mineActiveTime;
                                                if (boostInactivTime < mineActiveTime) {
                                                    // window.alert("mine se phele boost active huwa tha aur boost khatam ho chuka hai 005")
                                                } else {
                                                    let pph2 = (boostpercent / 60 / 60);
                                                    // totalPPH = totalPPH + pph2
                                                    coins = boostkitnaActivetha / 1000 * pph2 + coins;
                                                    // window.alert(boostkitnaActivetha / 1000 * pph2 + " mine se phele boost active huwa tha saur boost khatam ho chuka hai aur itna coin mila 006")
                                                }

                                            }

                                        }
                                        // if (timesrun == 1) {
                                        //     totalPPH = totalPPH * 36;
                                        // }

                                        if (timesrun == 2) {
                                            coins = coins - baseminedd;
                                            totalPPH = totalPPH - pph1;
                                        }
                                        if (timesrun == 3) {
                                            coins = coins - baseminedd;
                                            totalPPH = totalPPH - pph1;
                                            settpph(((totalPPH * 60 * 60).toFixed(2)))
                                            setInterval(() => {
                                                let comma = (coins += totalPPH).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                });
                                                setCoins((comma))
                                            }, 1000);
                                        }

                                    }
                                } else if (inactivetime < new Date().getTime()) {
                                    let minedCoin = 24 * (baseMiningSpeed + incInvitePercent);
                                    timesrun = timesrun + 1;
                                    baseminedd = minedCoin;
                                    // window.alert(" 007 mine inactive ho gaya hai aur itna coin mila hai " + minedCoin)
                                    coins = coins + minedCoin
                                    if (mineactivetime < boostactivetime) {
                                        const mineActiveTime = new Date(mineactivetime).getTime();
                                        if (boostInactivTime < mineActiveTime) {
                                            // window.alert(" 008 boost mine ke bad active huwa hai aur mine abhi in active hai aur apke pas koi boost nahi bacha hai")
                                        } else {
                                            const kitnachalaBoost = inactivetime - firstBoostTime;
                                            let pph2 = (boostpercent / 60 / 60);
                                            totalPPH = totalPPH + pph2
                                            coins = kitnachalaBoost / 1000 * pph2 + coins;
                                            // window.alert(" 009 boost mine ke bad active huwa hai aur mine abhi in active hai aur boost se itna coin mila hai " + kitnachalaBoost / 1000 * pph2)
                                        }
                                    } else if (mineactivetime > boostactivetime) {

                                        const mineActiveTime = new Date(mineactivetime).getTime();
                                        const boostkitnaActivetha = boostInactivTime - mineActiveTime;
                                        if (boostInactivTime < mineActiveTime) {
                                            // window.alert(" 0010mine inactive ho chuka hai ur mine se phele boost start huwa tha aur aapke pass koi boost nahi hai")
                                        } else {
                                            let pph2 = (boostpercent / 60 / 60);
                                            totalPPH = totalPPH + pph2;
                                            coins = boostkitnaActivetha / 1000 * pph2 + coins;
                                            // window.alert("0011mine inactive ho chuka hai ur mine se phele boost start huwa tha aur itna coin mila hai " + boostkitnaActivetha / 1000 * pph2)
                                        }
                                    }

                                    if (timesrun == 2) {
                                        coins = coins - baseminedd;
                                        totalPPH = totalPPH - (baseMiningSpeed / 60 / 60);
                                    }
                                    if (timesrun == 3) {
                                        coins = coins - baseminedd;
                                        totalPPH = totalPPH - (baseMiningSpeed / 60 / 60);
                                    }
                                    settpph((0).toFixed(2))
                                    setCoins((coins).toFixed(2))
                                }
                            }
                            boostTimer(data.bActiveThreeT);
                            timeRemainFun(data.mineActiveTime);
                            progressbarFun(data.mineActiveTime);
                            coinsFun(data.mineActiveTime, data.bActiveOneT);
                            coinsFun(data.mineActiveTime, data.bActiveTwoT);
                            coinsFun(data.mineActiveTime, data.bActiveThreeT);
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                getUniversalData();
            }
        } catch (err) {
            console.log(err)
        }
    }

    const boostfunbtn = () => {
        const addDekhliya = async () => {
            try {
                let teleid = alldata.user.id;
                const currenttime = new Date();
                const res = await fetch("/add-dekh-liya", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        teleid, currenttime
                    })
                });
                

                if (res.status === 422) {
                    window.alert("boost problum")
                } else if (res.status === 201) {
                    // getUserData();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }

            } catch (err) {
                console.log(err)
            }
        }
        addDekhliya();
    }

    const collectbtnClick = () => {
        if (boostStreekDays <= boostStreek) {
            const steekReward = userRawData.BSRtoken;
            const BSRTadd = 100;
            const increaseSetBStreek = universalData.setStreekDays;

            const STcollect = async () => {
                try {
                    let teleid = alldata.user.id;
                    const res = await fetch("/streek-token-collect", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            steekReward, BSRTadd, increaseSetBStreek, teleid
                        })
                    });
                    if (res.status === 422) {
                        window.alert("bhaiya ji kuch problem aa gai hai")
                    } else if (res.status === 201) {
                        getUserData();
                    }
                } catch (err) {
                    console.log(err)
                }
            }
            STcollect();
        }
    }

    const mineactivate = async () => {

        try {
            let teleid = alldata.user.id;
            const currentT = new Date();
            const res = await fetch("/update-mine-time", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    currentT, teleid, coin
                })
            });
            if (res.status === 422) {
                window.alert("bhaiya ji kuch problem aa gai hai")
            } else if (res.status === 201) {
                getUserData();
            }
        } catch (err) {
            console.log(err)
        }
    }

    const checkingBoosts = async () => {
        try {
            let teleid = alldata.user.id;
            const res = await fetch("/check-boosts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    teleid
                })
            });
            if (res.status === 422) {
                window.alert("idhar se telegram ki id empty gayi hai")
            } else if (res.status === 403) {

                getUserData();
            } else if (res.status === 204) {
                // data hi nahi mila hame 

                getUserData();
            }
        } catch (err) {
            console.log(err)
        }
    }

    const verifyingId = async () => {
        const userUnsafeData = alldata.hash;
        const userData = window.Telegram.WebApp.initData;
        try {
            const res = await fetch("/verefing", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userUnsafeData, userData
                })
            });
            if (res.status === 422) {
                window.alert("Please Restart Bot")
            } else if (res.status === 403) {
                window.alert("Lol 😂")
            } else {
                // data hi nahi mila hame 
                checkingBoosts();
            }
        } catch (err) {
            console.log(err)
        }
    }
    const showSup36 = () => {
        setDisplaypop(2)
    }
    const showpopShildbtn = () => {
        setDisplaypop(1)
    }
    const ShowpopShild = () => {
        if (displaypopup === 1) {

            return (
                <>
                    <div className="popupContentall">
                        <h2>Boost your mining speed and your fun! </h2>
                        <p>Team up with friends, compete your shield , and climb the leaderboards to become the ultimate mining Biradar.</p>
                        <div className="su36logoandh3">
                            <h3>Get 36X Invite Bonus</h3>
                            <img src={active36} alt="" />
                        </div>
                        <div className="popupInvite">
                            <div className="popupinvitecont">
                                <h3>Invite a friend</h3>
                                <div><span>Get - {universalData.referalCoin ? (universalData.referalCoin).toLocaleString() : universalData.referalCoin}</span></div>
                            </div>
                            <div className="popupiviteBtncont">
                                <CopyToClipboard text={invitelink}
                                    onCopy={() =>
                                        setTimeout(() => {
                                            setCopyed(false)
                                        }, 5000)
                                    }
                                >
                                    <button onClick={() =>
                                        setCopyed(true)}>{copyed ? "Copied" : "invite"}</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (displaypopup === 2) {
            return (
                <>
                    <div className="popupContentall">
                        <h2><img src={active36} alt="" />36X the Rewards, 36X the Fun!</h2>
                        <p>The ultimate boost for your mining adventure! For the next 36 days only, enjoy a massive 36x invite bonus. This is a once-in-a-lifetime opportunity to invite friends, mine faster, and watch your coin stash grow like never before.</p>
                        <div className="popupInvite">
                            <div className="popupinvitecont">
                                <h3>Invite a friend</h3>
                                <div><span>Get - {universalData.referalCoin ? (universalData.referalCoin).toLocaleString() : universalData.referalCoin}</span></div>
                            </div>
                            <div className="popupiviteBtncont">
                                <CopyToClipboard text={invitelink}
                                    onCopy={() =>
                                        setTimeout(() => {
                                            setCopyed(false)
                                        }, 5000)
                                    }
                                >
                                    <button onClick={() =>
                                        setCopyed(true)}>{copyed ? "Copied" : "invite"}</button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
    const showAndClosePopupmess = (e) => {
        if (e.target.className === "showPopup") {
            setDisplaypop(0)
        }
    }
    const dailyTaskClaimBTN = async () => {
        let teleid = alldata.user.id;
        const res = await fetch("/claim", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                teleid
            })

        });
        await res.json()
        if (res.status === 200) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            window.alert("please reload tha page")
        }

    }

    const dailyTasks =  (rawDate) => {

        let utcMonth = new Date().getMonth();
        let utcYear = new Date().getFullYear();
        let utcDate = new Date().getDate()
        const collectDate = new Date(rawDate);
        const utcCurrentDate = new Date(utcYear, utcMonth, utcDate).getTime();

        if (utcCurrentDate > new Date(collectDate).getTime()) {
            setShowClaim(true)
        }
    }

    useEffect(() => {
        // checkingBoosts();
        if (window.location.pathname != "/page") {
            if (alldata.user) {
                // verifyingId();
                checkingBoosts();
            } else {
                window.location.href = 'https://36biradri.xyz/page'
            }
        }
    }, [])

    if (window.location.pathname == "/page") {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route exact path="/page" element={<Website />} />
                    </Routes>
                </BrowserRouter>
            </>
        )
    } else {

        return (
            <>
                <BrowserRouter>
                    <div className={displaypopup >= 1 ? "showPopup" : "hidePopup"} onClick={showAndClosePopupmess}>
                        <div id="messagebox">
                            <ShowpopShild />
                        </div>
                    </div>
                    <div className="allContent">
                        <div className="navbar">
                            <header>
                                <div className="header_first_fox">
                                    <div className="logo_and_name">
                                        <img src={nameLogo} alt="..." />
                                        <div><span>{(userRawData.teleName)}</span></div>
                                    </div>
                                    <div className="taskToken">
                                        <img src={taskCoin} alt="..." />
                                        <div><span>{userRawData.taskToken}</span></div>
                                    </div>
                                </div>
                                <div className="invited_Shild">
                                    <div className="super36">
                                        <div className={universalData.super36 ? "active36" : "inactive36"}>
                                            <div onClick={showSup36} className="activecontent">
                                                <div className="textcont">
                                                    <h2>Get 36X</h2>
                                                    <div className='supTimer'><span>{supdays} : {suphr} : {supmin} : {supsec}</span></div>
                                                </div>
                                                <img src={active36} alt="..." />
                                            </div>
                                            <div className="timeleft_progressbar">
                                                <Line percent={supdays * 2.7} strokeWidth={5} strokeColor="rgba(22, 241, 2, 1)" trailWidth={5} trailColor="rgba(172, 169, 169, 1)" />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="bothIS">
                                        <div className="IandSbox" onClick={showpopShildbtn}>
                                            <div>
                                                <span>{bandaInvited <= 9 ? "0" + bandaInvited : bandaInvited}</span>
                                            </div>
                                            <img className='IandSboximg1' src={invite_Icon} alt="..." />
                                        </div>
                                        <div className="IandSbox" onClick={showpopShildbtn}>
                                            <div>
                                                <span>{bandaInvited > 5 ? 100 : bandaInvited * 20}%</span>
                                            </div>
                                            <img className='IandSboximg2' src={bandaInvited <= 2 ? redShild : bandaInvited <= 4 ? yellowShild : shild} alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                        <div className="MinView">
                            <Routes>
                                <Route exact path="/" element={
                                    <>
                                        <div className="potli_andTCoin">
                                            <div className='pAndC'>
                                                <img src={potli} alt=".." />
                                                <div>
                                                    <span>{coin}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="timeRemainbox">
                                            <span><img src={clock} alt="..." />{hr}:{min}:{sec}</span>
                                        </div>
                                        <div className="CoinAllcont">
                                            <div className='circleNameAndCoinimg'>
                                                <div className="circleline">
                                                    {
                                                        <CircularProgressbar
                                                            value={progress}
                                                            strokeWidth={4.5}
                                                            styles={buildStyles({
                                                                strokeLinecap: 'butt',
                                                                pathTransitionDuration: 1.2,
                                                                pathColor: 'rgba(22, 241, 2, 1)',
                                                                trailColor: 'rgba(172, 169, 169, 1)',
                                                                backgroundColor: 'transparent',
                                                            })}
                                                        />
                                                    }

                                                </div>
                                                <div className="imgBox">
                                                    <img src={coinpic} alt="..." />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="speed_andBoostBox">
                                            <div className="speed">
                                                <div><img src={bijli} alt="..." /></div>
                                                <div><span>+{tpph} CBC/hr</span></div>
                                            </div>
                                            <div className="boost">
                                                {
                                                    mineinactiveTime <= new Date() ? <div className="boostactivatebtn">
                                                        <button onClick={mineactivate}>START</button>
                                                    </div> : <Link className='boostBtn' to="/boost">
                                                        <div>
                                                            <img src={boostRocket} alt="..." />
                                                        </div>
                                                        <div>
                                                            <span>BOOST</span>
                                                        </div>
                                                    </Link>
                                                }
                                            </div>
                                        </div>
                                    </>
                                } />

                                <Route exact path="/boost" element={
                                    <>
                                        <div className="B_current_speed_andcoin">
                                            <div><span>CURRENT BALANCE</span></div>
                                            <div><span>CURRENT SPEED</span></div>
                                        </div>
                                        <div className="totalCoin_and_speed">
                                            <div className="coinBox">
                                                <div><img src={potli} alt="..." /></div>
                                                <div><span>{coin}</span></div>
                                            </div>
                                            <div className="tSpeedBox">
                                                <div><img src={bijli} alt="..." /></div>
                                                <div><span>+{tpph} CBC/hr</span></div>
                                            </div>
                                        </div>
                                        <div className="baseSpeed_bande_rocket_box">
                                            <div className="allthree">
                                                <div className="baseSpdeed">
                                                    <div><img src={auzaar} alt="..." /></div>
                                                    <div><span>+{universalData.baseMspeed}/hr</span></div>
                                                </div>
                                                <div className="bande">
                                                    <div><img className='tinvitsimg1' src={invite_Icon} alt="..." /></div>
                                                    <div><span>{universalData.inviteIncreadePerc * bandaInvited}%</span></div>
                                                </div>
                                                <div className="rocket">
                                                    <div><img src={haraRocket} alt="..." /></div>
                                                    <div><span>{300 - (100 * userRawData.boostsLeft)}%</span></div>
                                                </div>
                                            </div>

                                            <div className="dailyBoosters">
                                                <div className="textNadbtn">
                                                    <h2>Daily Boosts</h2>
                                                    <div className="available_bost">
                                                        <span>{userRawData.boostsLeft}/{universalData.setBoosts} Available</span>
                                                    </div>
                                                </div>
                                                {
                                                    new Date(userRawData.bActiveThreeT).getTime() + 24 * 1000 * 60 * 60 > new Date() ? <div className="boostCountDown">
                                                        <span>{boosthr}:{boostmin}:{boostsec}</span>
                                                    </div> : <button disabled={userRawData.boostsLeft <= 0 ? true : false} onClick={boostfunbtn}>Boost Now</button>
                                                }

                                            </div>

                                            <div className="boostsStreek">
                                                <h2>Boost Streek</h2>
                                                <div className="line">
                                                    <input disabled type="range" id="volume" value={userRawData.boostStreek} min="0" max={userRawData.remainBoostStreek} />
                                                    {/* <Line percent={80} strokeWidth={2.8} strokeColor="rgba(255, 176, 0, .4)" trailWidth={2.8} trailColor="rgba(172, 169, 169, 1)" /> */}
                                                </div>
                                                <div className="srikCount">
                                                    <span>{userRawData.boostStreek}/{userRawData.remainBoostStreek} Days</span>
                                                </div>
                                                <div className="collectBox">
                                                    <div className="tskToken">
                                                        <img src={taskCoin} alt="..." />
                                                        <div><span>{userRawData.BSRtoken}</span></div>
                                                    </div>
                                                    <div className="collectBtn">
                                                        <button onClick={collectbtnClick} disabled={userRawData.boostStreek >= userRawData.remainBoostStreek ? false : true}>Collect</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                } />

                                <Route exact path="/my-team" element={
                                    <>
                                        <div className="taskAllcont">
                                            <h1>MY TEAM</h1>

                                            <div className="allthree2">
                                                <div className="baseSpdeed">
                                                    <div><img src={auzaar} alt="..." /></div>
                                                    <div><span>+{universalData.baseMspeed}/hr</span></div>
                                                </div>
                                                <div className="bande">
                                                    <div><img src={invite_Icon} alt="..." /></div>
                                                    <div><span>{universalData.inviteIncreadePerc * bandaInvited}%</span></div>
                                                </div>
                                                <div className="rocket">
                                                    <div><img src={haraRocket} alt="..." /></div>
                                                    <div><span>{300 - (100 * userRawData.boostsLeft)}%</span></div>
                                                </div>
                                            </div>
                                            <div className="tShildAndSize">
                                                <div className="tsSboxes">
                                                    <h2>TEAM SIZE</h2>
                                                    <div className='tinvits'><div><span>{bandaInvited <= 9 ? "0" + bandaInvited : bandaInvited}</span></div><img className='tinvitsimg1' src={invite_Icon} alt=".." /></div>
                                                </div>
                                                <div className="tsSboxes">
                                                    <h2>TEAM SHIELD</h2>
                                                    <div className='tinvits'><span>{bandaInvited > 5 ? 100 : bandaInvited * 20}%</span><img className='tinvitsimg2' src={bandaInvited <= 2 ? redShild : bandaInvited <= 4 ? yellowShild : shild} alt=".." /></div>
                                                </div>
                                            </div>
                                            <div className="inviteAfrend">
                                                <div className="icontent">
                                                    <h3>Invite a friend <img src={bijli} alt="..." /> <span>+{universalData.inviteIncreadePerc}%</span></h3>
                                                    <div className='inviteSpan1'><span>Get - {universalData.referalCoin ? (universalData.referalCoin).toLocaleString() : universalData.referalCoin} CBC</span></div>
                                                </div>
                                                <div className="ibbox">
                                                    <CopyToClipboard text={invitelink}
                                                        onCopy={() =>
                                                            setTimeout(() => {
                                                                setCopyed(false)
                                                            }, 5000)
                                                        }
                                                    >
                                                        <button onClick={() =>
                                                            setCopyed(true)}>{copyed ? "Copied" : "Invite"}</button>
                                                    </CopyToClipboard>

                                                </div>
                                            </div>
                                            <div className="bandaimg">

                                                {bandas ?
                                                    bandas.map((e) => (
                                                        <div key={e.inviteID} className="bimgAnd_name">
                                                            <img src={nameLogo} alt="" />
                                                            <div><span>{(e.inviteName).length > 15 ? (e.inviteName).substring(0, 15) + ".." : e.inviteName}</span></div>
                                                        </div>
                                                    )) : <div></div>
                                                }

                                                {
                                                    bandaInvited === 0 ? <div>
                                                        <p className='no_invited'>You Don't Have Friends</p>
                                                    </div> : <div></div>
                                                }

                                            </div>
                                        </div>
                                    </>
                                } />

                                <Route exact path="/tasks" element={
                                    <>
                                        <div className="taskAllcont">
                                            <h1>DAILY TASK</h1>
                                        </div>
                                        <div className="allboxes">
                                            <div className="dailyTaskAllcont">

                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 1</span>
                                                    </div>
                                                    <div className={activeClaim >= 2 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTCoinIR' src={dtCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day1}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 1 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 2</span>
                                                    </div>
                                                    <div className={activeClaim >= 3 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTCoinIR' src={dtCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day2}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 2 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 3</span>
                                                    </div>
                                                    <div className={activeClaim >= 4 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTGCoinIR' src={taskCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day3}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 3 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 4</span>
                                                    </div>
                                                    <div className={activeClaim >= 5 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTCoinIR' src={dtCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day4}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 4 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 5</span>
                                                    </div>
                                                    <div className={activeClaim >= 6 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTGCoinIR' src={taskCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day5}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 5 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                                <div className="DTSBallcont">
                                                    <div className="taskDay">
                                                        <span>Day 6</span>
                                                    </div>
                                                    <div className={activeClaim >= 7 ? "loOpec" : "DcoinBox"}>
                                                        <div className="dcImg">
                                                            <img className='DTCoinIR' src={dtCoin} alt="..." />
                                                        </div>
                                                        <div className="DtC">
                                                            <span>{universalData.day6}</span>
                                                        </div>
                                                    </div>
                                                    <div onClick={dailyTaskClaimBTN} className={activeClaim === 6 ? showClaim ? "activeClaim" : "inactiveClaim" : "inactiveClaim"}>
                                                        <span>Claim</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={activeClaim >= 8 ? "loOpecday7allcont" : "day7allcont"}>
                                                <div className="day7H">
                                                    <span>Biradar’s Day</span>
                                                </div>

                                                <div className="DTganjabox7">
                                                    <img className='ganjaimg' src={taskCoin} alt="..." />
                                                    <span>{universalData.day7}</span>
                                                </div>
                                                <div onClick={dailyTaskClaimBTN} className={activeClaim === 7 ? showClaim ? "day7Collet" : "inactiveday7Collet" : "inactiveday7Collet"}>
                                                    <span>Claim</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                } />

                                <Route exact path="/roadmap" element={
                                    <>
                                        <Rodemap />
                                    </>
                                } />
                            </Routes>
                        </div>
                        <div className="footerr">
                            <Footer />
                        </div>
                    </div>
                </BrowserRouter>
            </>
        )
    }
}

export default Allthings