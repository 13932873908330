import React from 'react'
import weblogo from "../../resources/website/webLogo.svg"
import webShild from "../../resources/website/webShild.svg"
import webiImg from "../../resources/website/webiImg.svg"

function Nav() {
    return (
        <>
            <div className="navAllcont">

                <div className='webloboImg'>
                    <img src={weblogo} alt="..." />
                </div>
                <div className='webnavOtherImg'>
                    <img src={webShild} alt="..." />
                    <img src={webiImg} alt="..." />
                </div>

            </div>
        </>
    )
}

export default Nav