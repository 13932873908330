import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

function Rodemap() {
    const [activeHeading, setactiveHeading] = useState(1)
    const firstClick = ()=>{
        setactiveHeading(1)
    }
    const secondClick = ()=>{
        setactiveHeading(2)
    }
    const thirdClick = ()=>{
        setactiveHeading(3)
    }
    const fourthClick = ()=>{
        setactiveHeading(4)
    }
    const fifthClick = ()=>{
        setactiveHeading(5)
    }
    const sixthClick = ()=>{
        setactiveHeading(6)
    }
    return (
        <>
            <div className="taskAllcont">
                <h1>ROADMAP</h1>
                <div className="rodemapcontall">
                    <Swiper
                        slidesPerView={5}
                        height={170}
                        spaceBetween={0}
                        loop={true}
                        direction={'vertical'}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className={activeHeading === 1 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={firstClick} className={activeHeading === 1 ? "activeRodemap" : "inactiveRodemap"}>Why We Created This Coin ?
                                </h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeHeading === 2 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={secondClick} className={activeHeading === 2 ? "activeRodemap" : "inactiveRodemap"}>For how long will the coin be mined ?
                                </h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeHeading === 3 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={thirdClick} className={activeHeading === 3 ? "activeRodemap" : "inactiveRodemap"}>when will the coin be launched ?
                                </h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeHeading === 4 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={fourthClick} className={activeHeading === 4 ? "activeRodemap" : "inactiveRodemap"}>supply</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeHeading === 5 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={fifthClick} className={activeHeading === 5 ? "activeRodemap" : "inactiveRodemap"}>Note</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeHeading === 6 ? "amindiv" : "inamindiv"}>
                                <h3 onClick={sixthClick} className={activeHeading === 6 ? "activeRodemap" : "inactiveRodemap"}>Message</h3>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="gradieanPopup">
                    <p>
                        {activeHeading === 1 ? "Our exclusive coin mining adventure is set for a limited period of 36 weeks and 36 days. During this time, you’ll have the unique opportunity to mine as many coins as possible. Once this period ends, mining will stop, making the coins you’ve mined a rare and prized possession.":activeHeading === 2 ? "Our coin will be mined for 36 weeks and 36 days, after which mining will stop." : activeHeading === 3 ? "We will launch the coin as soon as mining stops":activeHeading === 4 ? "We will allocate 55% of the coins to the miners, with 6% to 14% reserved for project 	investors. We will retain 10% to 14% for ourselves and allocate 3% for marketing. The 	remaining coins will be distributed to miners in a second airdrop.": activeHeading === 5 ? "After completing this project, we plan to launch additional projects where you will be able to purchase boosters and other items using 36 Biradri Coin. All 36 Biradri Coins we collect from these projects will be burned. We've implemented several strategies to ensure the value of 36 Biradri Coin continues to rise. When the time is right, we will share more details on how we can further increase its price." : activeHeading === 6 ?"You must mine this coin, it will give you good profit in the coming time.": ""}
                        </p>
                </div>
            </div>
        </>
    )
}

export default Rodemap