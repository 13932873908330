import React, { useState } from 'react'
import { Link } from "react-router-dom";
import homelogo from "../resources/homeLogo.svg"
import myTeam from "../resources/wallet.svg"
import tasks from "../resources/tasks.svg"
import roadmap from "../resources/roadMap.svg"
import inactiveHome from "../resources/inactiveHome.svg"
import activeRodeMp from '../resources/activeRodeMp.svg'
import activeTask from "../resources/activeTask.svg"
import activeTeam from "../resources/activeTeam.svg"

function Footer() {

  const [activeFooter, setactiveFooter] = useState(0)

  const active0 = () => {
    setactiveFooter(0)
  }
  const active1 = () => {
    setactiveFooter(1)
  }
  const active2 = () => {
    setactiveFooter(2)
  }
  const active3 = () => {
    setactiveFooter(3)
  }

  return (
    <>
      <footer>
        <Link to="/">
          <div onClick={active0} className="logoAndFont">
            <img src={activeFooter === 0 ? homelogo : inactiveHome} alt="..." />
            <div className={activeFooter === 0 ? "activeFtText" : "inactiveFtText"}><span>HOME</span></div>
          </div>
        </Link>
        <Link to="/my-team">
          <div onClick={active1} className="logoAndFont">
            <img className='myTeamIcon' src={activeFooter === 1 ? activeTeam : myTeam} alt="..." />
            <div className={activeFooter === 1 ? "activeFtText" : "inactiveFtText"} ><span>MY TEAM</span></div>
          </div>
        </Link>
        <Link to="/tasks">
          <div onClick={active2} className="logoAndFont">
            <img src={activeFooter === 2 ? activeTask :tasks} alt="..." />
            <div className={activeFooter === 2 ? "activeFtText" : "inactiveFtText"} ><span>TASKS</span></div>
          </div>
        </Link>
        <Link to="/roadmap">
          <div onClick={active3} className="logoAndFont">
            <img src={activeFooter === 3 ? activeRodeMp : roadmap} alt="..." />
            <div className={activeFooter === 3 ? "activeFtText" : "inactiveFtText"}><span>ROADMAP</span></div>
          </div>
        </Link>
      </footer>
    </>
  )
}

export default Footer