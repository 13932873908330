import React from 'react'
import Nav from './Nav'
import MediaQuery from 'react-responsive'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import exchangeimg from "../../resources/website/exchanges.svg"
import twitter from "../../resources/website/twitter.svg"
import telegram from "../../resources/website/telegram.svg"
import other from "../../resources/website/other.svg"
import pcmobile from "../../resources/website/pcMobile.png"
import weblogo from "../../resources/website/webLogo.svg"
import mobilepic from "../../resources/website/mobilepic.png"
import pic1 from "../../resources/website/1.jpg"
import pic2 from "../../resources/website/2.jpg"
import pic3 from "../../resources/website/3.jpg"


function Website() {
  return (
    <>
      <div className="websitAllcont">
        <div className="webnavbar">
          <Nav />
        </div>
        <div className="webmainScreen">
          <div className="webmainScreen1box">
            <h1>CHHATTIS BIRADRI</h1>
            <div className="webspans">
              <div>
                <span>UNCHAIN YOUR SPIRIT</span>
              </div>
              <div>
                <span className='webspSpan'>|</span>
              </div>
              <div>
                <span>IGNITE YOUR FUTURE</span>
              </div>
            </div>
            <MediaQuery maxWidth={600}>
              <div className="mobile">
                <img src={mobilepic} alt="..." />
              </div>
            </MediaQuery>
            <div className="exchangeImg">
              <img src={exchangeimg} alt="..." />
            </div>

            <div className="webusers">
              <div className="webusersbosex">
                <div className="bigspan">
                  <span>24/7</span>
                </div>
                <div className="smallSpan">
                  <span>SERVICE</span>
                </div>
              </div>
              <div className="webusersbosex">
                <div className="bigspan">
                  <span>2 Million</span>
                </div>
                <div className="smallSpan">
                  <span>CURRENT USERS</span>
                </div>
              </div>
              <div className="webusersbosex">
                <div className="bigspan">
                  <span>4.5+</span>
                </div>
                <div className="smallSpan">
                  <span>MARKET RATING</span>
                </div>
              </div>
            </div>
            <div className="getSetPlay">
              <div className="getSet">
                <span>GET SET</span>
              </div>
              <div className="play">
                <a href="https://t.me/chhattisbiradri_bot/chhattisbiradri">START</a>
              </div>
            </div>
            <div className="socialLinks">
              <div className="socialLinks">
                <a href="#"><img src={telegram} alt="..." /></a>
                <a href="#"><img src={twitter} alt="..." /></a>
                <a href="#"><img src={other} alt="..." /></a>
              </div>
            </div>
          </div>
          <MediaQuery minWidth={600}>
            <div className="mobile">
              <img src={pcmobile} alt="..." />
            </div>
          </MediaQuery>

        </div>
        <div className="eventsMainBOx">
          <div className="eventsSecondBox">
            <div className="allsingleBoxes">
              <MediaQuery minWidth={600}>
                <Swiper
                  spaceBetween={28}
                  slidesPerView={3}
                  loop={true}

                >
                  <SwiperSlide>
                    <img className='sliderimg' src={pic3} alt="..." />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img className='sliderimg' src={pic2} alt="..." />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img className='sliderimg' src={pic1} alt="..." />
                  </SwiperSlide>

                </Swiper>

              </MediaQuery>
              <MediaQuery maxWidth={600}>
                <Swiper
                  spaceBetween={15}
                  slidesPerView={2}
                  loop={true}
                >
                  <SwiperSlide>
                    <img className='sliderimg' src={pic3} alt="..." />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img className='sliderimg' src={pic2} alt="..." />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img className='sliderimg' src={pic1} alt="..." />
                  </SwiperSlide>
                </Swiper>

              </MediaQuery>
            </div>

            <div className="eventsPic">
              <div className="eventPicContent">
                <img src={weblogo} alt="..." />
                <div className='rotate'><span>EVENTS</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Website