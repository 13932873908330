import './App.css';
import './Web.css'
import Allthings from './components/Allthings';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Website from './components/website/Website';

function App() {
  // if (window.location.host.split(".")[0] == "mine") {
  //   return (
  //     <>
  //       <BrowserRouter>
  //         <Routes>
  //           <Route exact path="/" element={<Website />} />
  //         </Routes>
  //       </BrowserRouter>
  //     </>
  //   );
  // }
  // else {
  //   return (
  //     <>
  //       <BrowserRouter>
  //         <Routes>
  //           <Route exact path="/" element={<Allthings />} />
  //         </Routes>
  //       </BrowserRouter>

  //     </>
  //   );
  // }

  return (
        <>
          <Allthings/>
        </>
      );

}

export default App;
